////
/// Defines variables relating to global site layout and structure.
/// @group variables/layout
////

@use "./colors" as *;

// Site header layout
$header-height: 60px !default;
$header-height-desktop: 60px !default;
$header-utility-bar-height: 40px !default;
$header-loading-bar-height: 3px !default;
$header-loading-bar-color: $color-white !default;
$header-icon-size: $header-height - 10px !default;

// General layout
$wrapper-max-width: 1900px !default;

// These variables indicate the possible max widths for page content.
// If the viewport were to become larger than the set width,
// the content wrapper will stay centered and take the max-width;
// effectively "boxing" its contents. This is to prevent the page's
// content from becoming too stretched on larger screens.
$page-content-lg-max-width: 1920px !default;
$page-content-md-max-width: 1200px !default;
$page-content-sm-max-width: 900px !default;
$page-content-xs-max-width: 850px !default;

// Padding applied to page content wrappers.
$page-content-padding: 0 15px 15px !default;

// GDPR layout
$gdpr-modal-header-height: 44px;

// DEPRECATED VARS
// TODO: Replace instances of these vars with the matching vars above.
$wrapper-max-width: $page-content-lg-max-width !default;
$content-wrapper-max-width: $page-content-md-max-width !default;
$content-wrapper-medium-width: $page-content-xs-max-width !default;
$content-wrapper-padding: $page-content-padding !default;
